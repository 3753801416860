import React from 'react';
import travelLogo from '../img/plane.png';
import instaLogo from '../img/insta.png';
import linkLogo from '../img/link.png';
import mediumLogo from '../img/medium.png';

const SocialGrid = () => {
  const socialCards = [
    {
      title: "Travel Blog",
      image: travelLogo,
      description: "Sign up here to get updates as I venture around the world",
      link: "https://lucasmjennings.substack.com/subscribe"
    },
    {
      title: "Instagram",
      image: instaLogo,
      description: "This is where I post pictures (mostly of my travels)",
      link: "https://www.instagram.com/lucasjennings1/"
    },
    {
      title: "LinkedIn",
      image: linkLogo,
      description: "Professional updates and reposts about cool startups",
      link: "https://www.linkedin.com/in/lucasjennings1/"
    },
    {
      title: "Medium",
      image: mediumLogo,
      description: "Here's where I write about tech stuff",
      link: "https://medium.com/@LucasJennings"
    }
  ];

  return (
    <div className="container py-4">
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {socialCards.map((card, index) => (
          <div key={index} className="col">
            <a 
              href={card.link}
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div 
                className="card h-100 border border-dark rounded" 
                style={{ 
                  borderWidth: '2px',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                }}
                onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
                onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
              >
                <div className="card-body d-flex flex-column align-items-center">
                  <h5 className="card-title fw-bold mb-3 text-dark">{card.title}</h5>
                  <div 
                    className="mb-3 d-flex align-items-center justify-content-center"
                    style={{
                      width: 'min(8em, 20%)',     // Dynamic width with max percentage
                      aspectRatio: '1',           // Keep it square
                      minWidth: '4em',            // Minimum size
                      maxWidth: '120px'           // Maximum size on large screens
                    }}
                  >
                    <img 
                      src={card.image} 
                      alt={`${card.title} logo`} 
                      className="w-100 h-100"
                      style={{ 
                        objectFit: 'contain',
                        padding: '0.5em'
                      }}
                    />
                  </div>
                  <p className="card-text text-center text-muted">{card.description}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialGrid;