import React from 'react';

const Image = ({ src, alt }) => (
  <div className="container-fluid p-3">
    <div className="mx-auto" style={{ maxWidth: '768px' }}>
      <img src={src} alt={alt} className="img-fluid rounded-4" />
    </div>
  </div>
);

export default Image;