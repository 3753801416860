import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Image from './components/Image';

import { app, analytics } from './firebase';
import SocialGrid from './components/SocialGrid';

function App() {
  return (
    <div className="App">
      <Header text="Hi &#128075; - I'm Lucas" />
      <Image src="/img/BoliviaYellow.jpeg" />
      <SocialGrid />
    </div>
  );
}

export default App;
